import { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";

import { motion } from "framer-motion";

import Menu from "components/Menu";
import Navbar from "components/Navbar";
import Tab from "components/organisms/Tab";
import Tabs from "components/organisms/Tabs";
import Popup from "components/organisms/Popup";
import Input from "components/organisms/Input";
import Search from "components/organisms/Search";
import Button from "components/organisms/Button";
import TableComponent from "components/organisms/Table";

import { downloadIcon } from "icons/Icons";
import { buybackAPI } from "api/buybackAPI";

import { loginRedirect } from "hoc/LoginRedirect";

import { donwloadExcel, downloadFile } from "utils/api";
import { buildOptionString } from "utils/functions";
import { topToBottomSlideIn } from "utils/animations";
import { useTranslation } from "react-i18next";
import { roleAdmin, roleSuperAdmin } from "utils/constants";

const Invoice = () => {
  const { t } = useTranslation();
  const columnsUninvoiced = [
    {
      title: t("general.marketplace"),
      dataIndex: "name",
      key: "name",
      width: 150,
    },
    {
      title: t("transport-sheet"),
      dataIndex: "transport_document_id",
      key: "transport_document_id",
      width: 150,
    },
    {
      title: t("date-sent"),
      dataIndex: "shipment_date",
      key: "shipment_date",
      width: 150,
      render: (shipment_date: string) => (
        <p>
          {new Date(shipment_date).getDate() +
            "/" +
            (new Date(shipment_date).getMonth() + 1) +
            "/" +
            new Date(shipment_date).getFullYear()}
        </p>
      ),
    },
    {
      title: t("count-phones"),
      dataIndex: "count",
      key: "count",
      width: 150,
    },
    {
      title: t("general.sum"),
      dataIndex: "sum",
      key: "sum",
      width: 150,
      render: (sum: string) => <p>{Number(sum).toFixed(2)}</p>,
    },
    {
      title: t("download-sheet"),
      key: "operation",
      width: 200,
      render: (text: any, record: any, index: any) => (
        <div
          className="d-flex align-items-center"
          onClick={(e) => {
            downloadFile(`${record.filename}`, "trdoc");
          }}
        >
          <span
            className="icon start"
            dangerouslySetInnerHTML={{ __html: downloadIcon }}
          ></span>

          <a className="ml-2">{t("download-sheet")}</a>
        </div>
      ),
    },
  ];
  const columnsInvoiced = [
    {
      title: t("general.marketplace"),
      dataIndex: "name",
      key: "name",
      width: 150,
    },
    {
      title: t("transport-sheet"),
      dataIndex: "transport_document_id",
      key: "transport_document_id",
      width: 150,
    },
    {
      title: t("date-sent"),
      dataIndex: "shipment_date",
      key: "shipment_date",
      width: 150,
      render: (shipment_date: string) => (
        <p>
          {new Date(shipment_date).getDate() +
            "/" +
            (new Date(shipment_date).getMonth() + 1) +
            "/" +
            new Date(shipment_date).getFullYear()}
        </p>
      ),
    },
    {
      title: t("count-phones"),
      dataIndex: "count",
      key: "count",
      width: 150,
    },
    {
      title: t("general.sum"),
      dataIndex: "sum",
      key: "sum",
      width: 150,
      render: (sum: string) => <p>{Number(sum).toFixed(2)}</p>,
    },
    {
      title: t("download-sheet"),
      key: "operation",
      width: 200,
      render: (text: any, record: any, index: any) => (
        <div
          className="d-flex align-items-center"
          onClick={(e) => {
            downloadFile(`${record.filename}`, "trdoc");
          }}
        >
          <span
            className="icon start"
            dangerouslySetInnerHTML={{ __html: downloadIcon }}
          ></span>

          <a className="ml-2">{t("download-sheet")}</a>
        </div>
      ),
    },
    {
      title: t("date-invoice"),
      dataIndex: "bill_timestamp",
      key: "bill_timestamp",
      width: 150,
      render: (bill_timestamp: string) => (
        <>
          {bill_timestamp && (
            <p>
              {new Date(bill_timestamp).getDate() +
                "/" +
                (new Date(bill_timestamp).getMonth() + 1) +
                "/" +
                new Date(bill_timestamp).getFullYear()}
            </p>
          )}
        </>
      ),
    },
    {
      title: t("download-invoice"),
      key: "operation",
      width: 200,
      render: (text: any, record: any, index: any) => (
        <div
          className="d-flex align-items-center"
          onClick={(e) => {
            downloadFile(`${record.bill_filename}`, "bill");
          }}
        >
          <span
            className="icon start"
            dangerouslySetInnerHTML={{ __html: downloadIcon }}
          ></span>

          <a className="ml-2">{t("download-invoice")}</a>
        </div>
      ),
    },
  ];

  const { locationId } = useSelector((state: any) => state.location);
  const isMounted = useRef(true);
  const [data, setData] = useState<any[]>([]);
  const [stateData, setStateData] = useState<any[]>([]);
  const [unbilledData, setUnbilledData] = useState<any[]>([]);
  const [unbilledStateData, setUnbilledStateData] = useState<any[]>([]);
  const [elsPerPage, setElsPerPage] = useState(10);
  const [counter, setCounter] = useState(11);
  const [currentPage, setCurrentPage] = useState(1);
  const [pages, setPages] = useState<number[]>([]);
  const [columns, setColumns] = useState<any[]>(columnsUninvoiced);
  const [selectOptions, setSelectOptions] = useState<string[]>([]);
  const [searchBy, setSearchBy] = useState<any>("name");
  const [files, setFiles] = useState<any>([]);
  const [billNumber, setBillNumber] = useState<string>("");
  const [billValue, setBillValue] = useState<number>(0);
  const [billValueVariant, setBillValueVariant] = useState<any>("outlined");
  const [billValueMessage, setBillValueMessage] = useState<string>("");
  const [sRowsTotalValue, setSRowsTotalValue] = useState<number>(0);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [selectedRowsKeys, setSelectedRowsKeys] = useState<any[]>([]);
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<number>(1);
  const [isBilled, setIsBilled] = useState<boolean>(false);
  const { role } = useSelector((state: any) => state.login);


  enum ColumnsEnum {
    name = "Magazin",
    transport_document_id = "Fisa transport",
    shipment_date = "Data trimitere",
    count = "Numar telefoane",
    tracking_number = "AWB",
    shipment_status = "Status",
    sum = "Valoare",
    bill_timestamp = "Data factura",
  }

  useEffect(() => {
    buildSelectOptions();
    return () => {
      isMounted.current = false;
    };
  }, [columns]);

  useEffect(() => {
    getBilledData();
    getUnbilledData();
  }, []);

  useEffect(() => {
    if (isBilled) {
      setCounter(data.length);
    } else {
      setCounter(unbilledData.length);
    }
  }, [data, unbilledData]);

  useEffect(() => {
    handlePages();
    onChange(currentPage);
  }, [counter, elsPerPage]);

  const getTransportSheetsFromAPI = async (billed: boolean) => {
    try {
      const response = await buybackAPI.buybackShipped(
        locationId,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        billed
      );
      const { shipments } = response.data;

      return shipments;
      // setData(shipments);
      // setStateData(shipments);
    } catch (error) { }
  };

  const getBilledData = () => {
    getTransportSheetsFromAPI(true).then((res) => {
      // @ts-ignore
      setData(res);
      // @ts-ignore
      setStateData(res);
    });
  };

  const getUnbilledData = () => {
    getTransportSheetsFromAPI(false).then((res) => {
      // @ts-ignore
      setUnbilledData(res);
      // @ts-ignore
      setUnbilledStateData(res);
    });
  };

  const buildSelectOptions = () => {
    let keys: string[] = [];

    columns.map((column) => {
      if (column.key !== "operation") {
        keys.push(buildOptionString(column.title));
      }
    });

    setSelectOptions(keys);
  };

  const handlePages = () => {
    let nrOfPages = Math.ceil(counter / elsPerPage);
    let pagesArray: number[] = [];
    for (let i = 1; i <= nrOfPages; i++) {
      pagesArray.push(i);
    }
    setPages(pagesArray);
  };

  const onChange = (pageNumber: string | number) => {
    if (isBilled) {
      setStateData(
        data.slice(
          (Number(pageNumber) - 1) * elsPerPage,
          Number(pageNumber) * elsPerPage
        )
      );
    } else {
      setUnbilledStateData(
        unbilledData.slice(
          (Number(pageNumber) - 1) * elsPerPage,
          Number(pageNumber) * elsPerPage
        )
      );
    }
    setCurrentPage(Number(pageNumber));
  };

  const handleElsPerPage = (e: any) => {
    if (e < counter) {
      setElsPerPage(e);
    } else {
      setElsPerPage(counter);
    }
  };

  const handleSearch = (e: any) => {
    if (isBilled) {
      const filteredData = data.filter((el: any) => {
        if (el[searchBy]) {
          if (
            el[searchBy]
              .toString()
              .toLowerCase()
              .includes(e.target.value.toLowerCase())
          ) {
            return el;
          }
        }
      });
      setStateData(
        filteredData.slice(
          (Number(currentPage) - 1) * elsPerPage,
          Number(currentPage) * elsPerPage
        )
      );
    } else {
      const filteredData = unbilledData.filter((el: any) => {
        if (el[searchBy]) {
          if (
            el[searchBy]
              .toString()
              .toLowerCase()
              .includes(e.target.value.toLowerCase())
          ) {
            return el;
          }
        }
      });
      setUnbilledStateData(
        filteredData.slice(
          (Number(currentPage) - 1) * elsPerPage,
          Number(currentPage) * elsPerPage
        )
      );
    }
  };

  const handleSelect = (val: any) => {
    const valKey =
      Object.keys(ColumnsEnum)[
      Object.values(ColumnsEnum).indexOf(val as unknown as ColumnsEnum)
      ];
    valKey && setSearchBy(valKey);
  };

  const firstLetterToLowerCase = (val: string) => {
    if (val) {
      val = val.replace(" ", "_");
      val = val.charAt(0).toLowerCase() + val.slice(1);
      return val;
    }
  };

  const removeFile = (file: number) => {
    let filesCopy = [...files];
    let indexOf = filesCopy.indexOf(file);
    indexOf > -1 && filesCopy.splice(indexOf, 1);
    setFiles(filesCopy);
  };

  const uploadBill = async () => {
    let formData = new FormData();
    formData.append("file", files[0]);
    formData.append("locationId", locationId);
    formData.append(
      "transportDocumentId",
      selectedRows[0].transport_document_id
    );
    formData.append("billNumber", billNumber);

    const totalValue = calculateSRowsTotalValue();

    if (totalValue === billValue) {
      try {
        const response = await buybackAPI.upload(formData);
        setShowPopup(false);
        setSelectedRows([]);
        setSelectedRowsKeys([]);
        getBilledData();
        getUnbilledData();
      } catch (error) { }
    } else {
      setBillValueVariant("error");
      setBillValueMessage(
        t("sum-not-equal")
      );
    }
  };

  const calculateSRowsTotalValue = () => {
    let total: number = 0;
    selectedRows.map((r) => {
      total += parseFloat(r.sum);
    });
    return total;
  };

  const rowSelection = {
    onChange: (selectedRowKeys: any, selectedRows: any) => {
      setSelectedRows(selectedRows);
      setSelectedRowsKeys(selectedRowsKeys);
    },
    getCheckboxProps: (record: any) => ({
      name: record.name,
    }),
  };

  return (
    <>
      <Menu></Menu>
      <div className="page-content">
        <Navbar
          title={t("in-house")}
          subtitle={t("menu.invoice")}
          showCard={true}
          showGoBackButton={false}
          showCancelButton={false}
        ></Navbar>
        <motion.div
          initial="hidden"
          animate="enter"
          exit="exit"
          variants={topToBottomSlideIn}
          className="content d-flex"
        >
          <div className="flex-1 flow-container w-100 m-5">
            <Tabs>
              <Tab
                title={t("phones-not-invoiced")}
                number={unbilledData.length}
                isActive={activeTab === 1 ? true : false}
                onClickProp={() => {
                  setActiveTab(1);
                  setIsBilled(false);
                  setColumns(columnsUninvoiced);
                }}
              />
              <Tab
                title={t("phones-invoiced")}
                number={stateData.length}
                isActive={activeTab === 2 ? true : false}
                onClickProp={() => {
                  setActiveTab(2);
                  setIsBilled(true);
                  setColumns(columnsInvoiced);
                }}
              />
            </Tabs>
            <Search
              selectDefaultValue="magazin"
              selectOptions={selectOptions}
              onChange={handleSearch}
              handleSelect={handleSelect}
            >
              {
                role === roleSuperAdmin || role === roleAdmin && (
                  <Button
                    className="ml-4"
                    variant="primary"
                    size="small"
                    onClick={() => donwloadExcel(locationId, "shipments")}
                  >
                    {t("export-csv")}
                  </Button>
                ) || null
              }
              {activeTab === 1 && (
                <Button
                  className="ml-4"
                  variant={selectedRows.length === 0 ? "disabled" : "primary"}
                  disabled={selectedRows.length === 0 ? true : false}
                  size="small"
                  onClick={() => {
                    setShowPopup(true);
                  }}
                >
                  {t("upload-invoice")}
                </Button>
              )}

            </Search>

            <TableComponent
              pages={pages}
              data={isBilled ? stateData : unbilledStateData}
              columns={columns}
              total={isBilled ? data.length : unbilledData.length}
              pageSize={elsPerPage}
              handlePage={onChange}
              currentPage={currentPage}
              rowSelection={rowSelection}
              rowKey="transport_document_id"
              handleElementsPerPage={handleElsPerPage}
            />
          </div>
        </motion.div>
      </div>
      {showPopup && (
        <Popup
          icon="/images/folder2.png"
          headerText={t("invoice-data")}
          description={t("invoice-finalize")}
          closeHandler={() => {
            setFiles("");
            setShowPopup(false);
          }}
        >
          <Input
            type="text"
            size="medium"
            placeholder="Ex: 1222"
            startLabel={t("invoice-no")}
            classNameContainer="mt-3"
            classNameStartLabel="text-body float-left"
            classNameBottomIcon="mr-1"
            classNameBottomLabel="font-size-14 font-weight-regular text-dark text-align-start opacity-07"
            onChange={(e: any) => {
              setBillNumber(e.target.value);
              setBillValueMessage("");
            }}
          />
          <Input
            type="text"
            size="medium"
            variant={billValueVariant}
            placeholder="1500"
            startLabel={t("invoice-sum")}
            bottomLabel={billValueMessage}
            classNameContainer="mt-3"
            classNameStartLabel="text-body float-left"
            classNameBottomIcon="mr-1"
            classNameBottomLabel="font-size-14 font-weight-regular text-red text-align-start opacity-07"
            onChange={(e: any) => {
              setBillValue(parseFloat(e.target.value));
              setBillValueMessage("");
            }}
          />
          <Input
            id="file"
            type="file"
            variant="file"
            startLabel={t("uploading-invoice")}
            endLabel={t("upload-invoice")}
            classNameContainer="mt-3"
            classNameLabelContainer="d-flex flex-column align-items-start"
            classNameEndLabel="label-file w-100"
            onChange={(e: any) => {
              setFiles(e.target.files);
              setBillValueMessage("");
            }}
            onClick={(e: any) => removeFile(e)}
            selectedFiles={files}
          />
          <Button
            variant="primary"
            size="large"
            className="w-100 mt-5"
            onClick={() => {
              uploadBill();
            }}
          >
            {t("general.finalize")}
          </Button>
        </Popup>
      )}
    </>
  );
};

export default loginRedirect(Invoice);
