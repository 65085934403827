import { IStepperHeaderProps } from "interfaces/stepper";
import { useTranslation } from "react-i18next";

const StepperHeader = (props: IStepperHeaderProps) => {
  const { t } = useTranslation();

  const visibleSteps = props.steps.filter((elem) => { return !elem.isDisabled })

  return (
    <div>
      <div className="step-header-container d-flex flex-row mb-5">
        {visibleSteps.map((step, index) => (
          <div className="w-25" key={step.id + 1}>
            <div className="d-flex flex-column step-header-item">
              <div className="text-body text-align-start">
                {t('general.step')} {index + 1}
              </div>
              <div
                className={`title-text text-align-start  mb-3 ${step.isActive || step.isCompleted ? "text-dark" : "text-body"
                  }`}
              >
                {t(step.title)}
              </div>
              <div className="container-state">
                <div
                  className={`state ${step.isActive
                    ? "active"
                    : step.isCompleted
                      ? "completed"
                      : "default"
                    }`}
                ></div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default StepperHeader;
