import React, { forwardRef, useEffect, useState } from "react";
import { loadingIcon } from "icons/Icons";

export type ButtonVariant =
  | "primary"
  | "outlined"
  | "secondary"
  | "body"
  | "grey"
  | "warning"
  | "error"
  | "text"
  | "disabled";

export type ButtonImperativeRef = React.MutableRefObject<
  | {
      toggleWaiting?: (mode?: boolean) => void;
    }
  | null
  | undefined
>;

type ButtonProps = {
  id?: string;
  children?: React.ReactNode | React.ReactNode[];
  variant?: ButtonVariant;
  className?: string;
  size?: "xsmall"  | "small" | "medium" | "large" | "square";
  startIcon?: string;
  endIcon?: string;
  disabled?: boolean;
  wait?: boolean;
  iRef?: ButtonImperativeRef;
  type?: "submit";
  onClick: any;
};

/**
 * as a general rule, all custom components should be defined as forwardRefs
 * a lot of third party libs use cloneElement to apply their magic
 * and will complain when cloning a child which cannot hold a ref
 * either way, refs are needed
 */

function ButtonWithRef(props: ButtonProps, ref: any) {
  const {
    variant = "default",
    className: classNameProp,
    children,
    size = "medium",
    startIcon,
    endIcon,
    disabled: disabledProp,
    wait,
    onClick: onClickProp,
    iRef,
    type = "button",
    ...rest
  } = props;

  const [waiting, setWaiting] = useState(false);

  const disabled = disabledProp || waiting;

  useEffect(() => {
    if (iRef) {
      if (!iRef.current) {
        iRef.current = {};
      }
      iRef.current.toggleWaiting = (mode?: boolean) => {
        setWaiting((currentState) =>
          typeof mode === "boolean" ? mode : !currentState
        );
      };
    }
    return () => {
      if (iRef) {
        iRef.current = undefined;
      }
    };
  }, [waiting]);

  const onClick = (e: any) => {
    if (waiting) {
      return;
    }
    if (wait && !waiting) {
      setWaiting(true);
    }
    if (onClickProp) {
      onClickProp(e);
    }
  };

  return (
    <button
      type={type}
      disabled={disabled}
      className={`btn btn-${size} btn-${variant} ${disabled ? 'btn-disabled' : ''} ${
        (classNameProp && classNameProp) || ""
      }`}
      ref={ref}
      onClick={onClick}
      {...rest}
    >
      {startIcon ? (
        <span
          className="icon start"
          dangerouslySetInnerHTML={{ __html: startIcon }}
        ></span>
      ) : null}
      {children}
      {endIcon && !waiting ? (
        <span
          className="icon end"
          dangerouslySetInnerHTML={{ __html: endIcon }}
        ></span>
      ) : null}
      {loadingIcon && wait ? (
        <span
          className="icon end"
          dangerouslySetInnerHTML={{ __html: loadingIcon }}
        ></span>
      ) : null}
    </button>
  );
}

const Button = forwardRef(ButtonWithRef);

export default Button as typeof ButtonWithRef;
