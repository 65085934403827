import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { motion } from "framer-motion";

import { IStep } from "interfaces/stepper";
import { IPhoneDefects } from "interfaces/stepper";

import Popup from "components/organisms/Popup";
import Button from "components/organisms/Button";
import Textarea from "components/organisms/Textarea";

import HeaderFlow from "./HeaderFlow";
import StepperHeader from "./stepper/StepperHeader";
import FlowContentRendered from "./FlowContentRenderer";
import StepperActions from "./stepper/StepperActionsProps";
import PhoneIMEI from "./stepper/steps-components/PhoneIMEI";
import PhoneDetails from "./stepper/steps-components/PhoneDetails";
import BuyBackOffer from "./stepper/steps-components/BuybackOffer";
import PhoneRepairs from "./stepper/steps-components/PhoneRepairs";
import StepperContentRendered from "./stepper/StepperContentRendered";

import { Select } from "antd";

import { buybackAPI } from "api/buybackAPI";
import { topToBottomSlideIn } from "../../utils/animations";
import { selectOptions as options } from "../../utils/constants";

import {
  resetStateAction,
  setSellOrderIDAction,
  setBuybackOfferAction,
  setCancelReasonAction,
  setStepperFlowStepsAction,
  setUncheckedRepairsActions,
  setCurrentStepperFlowStepAction,
  setCancelAdditionalInformationAction, setRepairsAction,
  setIMEIAction,
} from "../../redux/actions/PhoneDetailsAction";
import { InputVariant } from "components/organisms/Input";
import { useTranslation } from "react-i18next";

const { Option } = Select;

interface StartFlowProps {
  nextProcessStep: any;
  badClientData: any;
}

const StartFlow = (props: StartFlowProps) => {
  const dispatch = useDispatch();
  const {
    IMEI,
    reason,
    repairs,
    defects,
    flowSteps,
    buybackID,
    sellerName,
    phoneDetails,
    additionalInformation,
    currentStepperFlowStep,
  } = useSelector((state: any) => state.phone);
  const { locationId } = useSelector((state: any) => state.location);

  const [phoneRepairs, setPhoneRepairs] = useState<IPhoneDefects[]>([]);
  const [phoneRepairsPossible, setPhoneRepairsPossible] = useState<any[]>([]);
  const [showPopup, setShowPopup] = useState(false);
  const [showCancelPopup, setShowCancelPopup] = useState(false);
  const [showInvalidBuybackOfferPopup, setShowInvalidBuybackOfferPopup] =
    useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [isPrinted, setIsPrinted] = useState(false);
  const [isLoadingNewOffer, setIsLoadingNewOffer] = useState(false);
  const [imeiVariant, setImeiVariant] = useState<InputVariant>("outlined");
  const [imeiErrorMessage, setImeiErrorMessage] = useState<string>("");
  const [popupMessage, setPopupMessage] = useState<string>("");
  const [showDisclaimer, setShowDisclaimer] = useState<boolean>(false);
  const [popupInvalid, setPopupInvalid] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const {  supplier } = useSelector((state: any) => state.login);

  const steps = [
    {
      id: 0,
      isActive: true,
      isDisabled: false,
      isCompleted: false,
      title: "general.phone-data",
      titleHeader: "general.phone-corrrect-data",
      subtitleHeader: "general.flippo",
      actionConfirm: "general.correct-data",
      actionDecline: "general.incorrect-data",
    },
    {
      id: 1,
      isActive: false,
      isDisabled: false,
      isCompleted: false,
      title: "general.imei-title",
      titleHeader: "general.imei-error",
      subtitleHeader: "general.flippo",
      actionConfirm: "general.continue",
    },
    {
      id: 2,
      isActive: false,
      isDisabled: false,
      isCompleted: false,
      title: "phone-defects",
      titleHeader:
        "other-defects",
      subtitleHeader: "general.flippo",
      actionConfirm: "general.continue",
    },
    {
      id: 3,
      isActive: false,
      isDisabled: false,
      isCompleted: false,
      title: "offer-buyback",
      titleHeader: "offer-subtitle",
      subtitleHeader: "general.flippo",
      actionConfirm: "accept",
      actionDecline: "decline",
    },
  ];

  useEffect(() => {
    setPhoneRepairs([]);
    getPhoneRepairs();
  }, []);

  const [selectedStep, setSelectedStep] = useState<IStep>(
    steps[currentStepperFlowStep]
  );
  const [selectedStepsState, setSelectedStepsState] = useState<IStep[]>(steps);

  const rejectBuyback = async () => {
    try {
      const response = await buybackAPI.buybackReject(
        buybackID,
        locationId[0],
        reason,
        additionalInformation
      );
    } catch (error) { }
  };

  const getNewBuybackOffer = async () => {
    const errorInfo = t("error.no-offer");
    try {
      setIsLoadingNewOffer(true);
      const response = await buybackAPI.buyback(
        buybackID,
        repairs
      );
      if (response.status !== 200) {
        dispatch(setBuybackOfferAction(0));
        setPopupInvalid(true);
        setErrorMessage(t("error.download"));
        setPopupMessage(errorInfo);
      }
      if (response.data.data) {
        const { offerWithRepairs } = response.data.data;

        if (offerWithRepairs) {
          if (offerWithRepairs >= 40) {
            dispatch(setBuybackOfferAction(offerWithRepairs));
          } else if (offerWithRepairs >= 0) {
            dispatch(setBuybackOfferAction(40));
          }
        }
      } else {
        const { errorCode } = response.data;

        errorCode && setPopupMessage(t(`error.${errorCode}`));
        rejectBuyback();
        dispatch(setBuybackOfferAction(0));
        setShowInvalidBuybackOfferPopup(true);
      }
    } catch (error) { }
    setIsLoadingNewOffer(false);
  };

  const getPhoneRepairs = async () => {
    try {
      const model = phoneDetails[1].value;
      const storage = phoneDetails[2].value;
      const response = await buybackAPI.repairs(model, undefined, storage);

      //const newRepairs = response.data.repairs;
      // newRepairs.map((elem)=>{
      //   elem['translated'] =
      // })

      setPhoneRepairsPossible(response.data.repairs);
      dispatch(setUncheckedRepairsActions(response.data.repairs));
    } catch (error) { }
  };

  const submitBuyback = async () => {
    try {
      const response = await buybackAPI.buybackSubmit(
        buybackID,
        locationId[0],
        sellerName,
        IMEI,
        repairs.concat(defects)
      );
      if(response.data.data.imei){
        dispatch(setIMEIAction(response.data.data.imei));
      }
      if (response.data.data.sellOrderId) {
        dispatch(setSellOrderIDAction(response.data.data.sellOrderId));
      }
    } catch (error) { }
  };

  const checkImeiFormat = () => {
    const onlyNumbersRegex = new RegExp("^[0-9]+$");
    if (!IMEI.match(onlyNumbersRegex) || IMEI.length !== 15) {
      return false;
    }
    return true;
  };

  const resetImeiValidation = () => {
    setImeiVariant("outlined");
    setImeiErrorMessage("");
  };

  const goNext = () => {
    if(selectedStep.id === 0){
      if (supplier.toLowerCase() === 'vodafone'){
        dispatch(setCurrentStepperFlowStepAction(currentStepperFlowStep + 3));
        setSelectedStep(selectedStepsState[selectedStep.id + 3]);
      
        let flowStepsCopy: any[] = [];
        for (let i = 0; i < flowSteps.length; i++) {
          flowStepsCopy[i] = JSON.parse(JSON.stringify(flowSteps[i]));
        }
        flowStepsCopy[currentStepperFlowStep].isActive = false;
        flowStepsCopy[currentStepperFlowStep].isCompleted = true;

        flowStepsCopy[currentStepperFlowStep + 1].isDisabled = true;
        flowStepsCopy[currentStepperFlowStep + 1].isCompleted = true;

        flowStepsCopy[currentStepperFlowStep + 2].isCompleted = true;
        flowStepsCopy[currentStepperFlowStep + 2].isDisabled = true;

        flowStepsCopy[currentStepperFlowStep + 3].isActive = true;
        dispatch(setStepperFlowStepsAction(flowStepsCopy));

        return;
      }
    }
    if (selectedStep.id === 3) {
      submitBuyback();
      props.nextProcessStep();
      return;
    }


    const isImeiValid = checkImeiFormat();

    if (selectedStep.id === 1 && !isImeiValid) {
      setImeiVariant("error");
      setImeiErrorMessage(t("error.imei"));
      return;
    }

    if (selectedStep.id === 2) {
      getNewBuybackOffer();
    }

    if (currentStepperFlowStep !== undefined) {
      let flowStepsCopy: any[] = [];
      for (let i = 0; i < flowSteps.length; i++) {
        flowStepsCopy[i] = JSON.parse(JSON.stringify(flowSteps[i]));
      }
      flowStepsCopy[currentStepperFlowStep].isActive = false;
      flowStepsCopy[currentStepperFlowStep].isCompleted = true;
      flowStepsCopy[currentStepperFlowStep + 1].isActive = true;
      dispatch(setStepperFlowStepsAction(flowStepsCopy));
    }

    dispatch(setCurrentStepperFlowStepAction(currentStepperFlowStep + 1));
    setSelectedStep(selectedStepsState[selectedStep.id + 1]);
  };

  const goPrev = () => {
    dispatch(setCurrentStepperFlowStepAction(currentStepperFlowStep - 1));

    if (currentStepperFlowStep !== undefined) {
      selectedStepsState[currentStepperFlowStep].isActive = false;
      selectedStepsState[currentStepperFlowStep].isCompleted = false;
      selectedStepsState[currentStepperFlowStep - 1].isActive = true;
    }

    setSelectedStep(selectedStepsState[selectedStep.id - 1]);
    setSelectedStepsState(selectedStepsState);
  };

  const cancelStepFlow = () => {
    if (selectedStep.id === 0) {
      props.badClientData();
    } else {
      goPrev();
      rejectBuyback();
    }
    dispatch(resetStateAction());
  };

  const { t } = useTranslation();

  return (
    <>
      <motion.div
        initial="hidden"
        animate="enter"
        exit="exit"
        variants={topToBottomSlideIn}
        className="content d-flex"
      >
        <div className="flex-1 flow-container w-100 flow-start">
          <FlowContentRendered>
            <StepperHeader steps={flowSteps} />
            <HeaderFlow
              title={selectedStep.titleHeader}
              subtitle={selectedStep.subtitleHeader}
            />
            <StepperContentRendered
              idSelectedStep={currentStepperFlowStep}
              isLoadingNewOffer={isLoadingNewOffer}
              showDisclaimer={showDisclaimer}
            >
              {currentStepperFlowStep === 0 && (
                <PhoneDetails
                  phoneDetails={phoneDetails}
                  directionContent="column"
                ></PhoneDetails>
              )}
              {currentStepperFlowStep === 1 && (
                <PhoneIMEI
                  imeiVariant={imeiVariant}
                  imeiErrorMessage={imeiErrorMessage}
                  resetImeiValidation={resetImeiValidation}
                ></PhoneIMEI>
              )}
              {currentStepperFlowStep === 2 && (
                <PhoneRepairs
                  phoneDefectsDetected={defects}
                  phoneRepairsPossible={phoneRepairsPossible}
                  setPhoneRepairs={setPhoneRepairs}
                  phoneRepairs={phoneRepairs}
                ></PhoneRepairs>
              )}
              {currentStepperFlowStep === 3 && (
                <BuyBackOffer phoneDetails={phoneDetails}></BuyBackOffer>
              )}
            </StepperContentRendered>
            <StepperActions
              actionConfirm={selectedStep.actionConfirm}
              actionDecline={selectedStep.actionDecline}
              onActionConfirm={() => goNext()}
              onActionDecline={() =>
                selectedStep.id === 3
                  ? setShowCancelPopup(true)
                  : cancelStepFlow()
              }
            />
          </FlowContentRendered>
        </div>
      </motion.div>
      {showInvalidBuybackOfferPopup && (
        <Popup
          icon={"/images/error-imei.png"}
          headerText={popupMessage}
          description={""}
        >
          <Button
            variant={"primary"}
            size="large"
            className="w-100 mt-5"
            onClick={() => {
              dispatch(resetStateAction());
            }}
          >
            {t("general.ok")}
          </Button>
        </Popup>
      )}
      {showCancelPopup ? (
        <Popup
          icon={"/images/thumbs-down.png"}
          headerText={t('offer-rejected')}
          description={
            t('offer-rejected-descr')
          }
          closeHandler={() => setShowCancelPopup(false)}
        >
          <Select
            className="popup-select w-100 mb-2"
            defaultValue={t('choose-reason')}
            dropdownStyle={{ zIndex: 9999, background: "white" }}
            onChange={(value) => dispatch(setCancelReasonAction(value))}
          >
            {options &&
              options.map((selectOption, index) => (
                <Option key={index} value={t(`refuseReason.${selectOption}`)}>
                {t(`refuseReason.${selectOption}`)}
              </Option>
              ))}
          </Select>
          <Textarea
            id="informatii"
            name="informatii"
            placeholder={t('extra-info')}
            onChange={(e: any) =>
              dispatch(setCancelAdditionalInformationAction(e.target.value))
            }
          />

          <Button
            variant="primary"
            size="large"
            className="w-100 mt-5"
            onClick={() => {
              rejectBuyback();
              dispatch(resetStateAction());
            }}
          >
            {t('general.confirm')}
          </Button>
        </Popup>
      ) : null}
      {popupInvalid && (
        <Popup
          icon={"/images/error-imei.png"}
          headerText={t('error.download')}
          description={errorMessage && errorMessage}
        >
          <Button
            variant={
              errorMessage.includes("DeniedByUser") ? "outlined" : "primary"
            }
            size="large"
            className="w-100 mt-5"
            onClick={() => {
              dispatch(resetStateAction());
            }}
          >
            {t("general.ok")}
          </Button>
        </Popup>
      )}
    </>
  );
};

export default StartFlow;
