const initialState = {
  locations: [],
  locationId: [],
  locationName: [],
};

export const locationReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case "SET_LOCATIONS":
      return { ...state, locations: action.payload };
    case "SET_LOCATION_ID":
      return { ...state, locationId: action.payload };
    case "SET_LOCATION_NAME":
      return { ...state, locationName: Object.values(action.payload) };
    case "RESET_LOCATION": {
      return {
        ...initialState,
      };
    }
    default:
      return state;
  }
};
