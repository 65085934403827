import { buybackInstance } from "./api";
import { RequestForDocument, RequestForVoucher } from "../types/types";
import { formatDateFilter } from "utils/functions";

export const buybackAPI = {
  session() {
    return buybackInstance.get<SessionResponseType>(`/session`);
  },

  buyback(buybackId: number | string, repairs?: string[]) {
    return buybackInstance.get<BuybackResponseType>(`/buyback/${buybackId}?${repairs && repairs.length > 0 && repairs?.map((r: string, index) => `repairs[${index}]=${r !== "LCDPixels" ? r.toUpperCase() : r}`).join('&') || ""}`);
  },

  buybackDelete(buybackId: number | string) {
    return buybackInstance.delete<DeleteResponseType>(`/buyback/${buybackId}`);
  },

  buybackSubmit(buybackId: number | string, locationId: number, sellerName: string, imei: string, repairs: string[]) {
    return buybackInstance.post<SubmitResponseType>("/buyback/submit", {
      buybackId,
      locationId,
      sellerName,
      imei,
      repairs
    })
  },

  buybackReject(buybackId: number | string, locationId: number, reason: string, additionalInformation: string) {
    return buybackInstance.post<RejectResponseType>("/buyback/reject", {
      buybackId, locationId, reason, additionalInformation
    })
  },

  buybackInventory(locationId?: any, selectedOption?: any) {
    let query = '';
    query += locationId && `?locationId=${locationId.includes("ALL") ? 'ALL' : locationId}` || '';
    if (selectedOption) {
      const dateQuery = '&startDate={dateStart}&endDate={dateEnd}'
      if (selectedOption.id === 'custom') {
        const dates = selectedOption.value.split("-");

        const dateStartSplit = dates[0].split("/");
        const dateStart = dateStartSplit[2] + "-" + dateStartSplit[1] + "-" + dateStartSplit[0];

        const dateEndSplit = dates[1].split("/");
        const dateEnd = dateEndSplit[2] + "-" + dateEndSplit[1] + "-" + dateEndSplit[0];

        const dateCustomQuery = dateQuery.replace("{dateStart}", dateStart).replace("{dateEnd}", dateEnd);
        query += dateCustomQuery;
      } else if (selectedOption.id !== "unset") {
        const prevDate = new Date();
        prevDate.setUTCDate(prevDate.getUTCDate() - selectedOption.id);
        const dateIntervalQuery =  dateQuery.replace("{dateStart}", formatDateFilter(prevDate)).replace("{dateEnd}", formatDateFilter(new Date()));

        query += dateIntervalQuery;


      }
    }

    return buybackInstance.get<any>(`/buyback/v2/inventory${query}`)
  },

  buybackInventoryDelete(orderIds: number[]) {
    return buybackInstance.delete<InventoryResponseType>(`/buyback/inventory`, { data: { orderIds: orderIds } })
  },

  buybackShipped(locationId?: any, limit?: number, offset?: number, status?: string, tracking_number?: string, sortBy?: string, sortDirection?: string, groupBy?: string | boolean, billed?: boolean) {
    return buybackInstance.get<ShippedResponseType>(`/buyback/shipments?${locationId && `locationId=${locationId.includes("ALL") ? 'ALL&' : locationId}&` || ''}${limit && `limit=${limit}&` || ""}${offset && `offset=${offset}&` || ""}${status && `status=${status}&` || ""}${tracking_number && `tracking_number=${tracking_number}&` || ""}${sortBy && `sortBy=${sortBy}&` || ""}${sortDirection && `sortDirection=${sortDirection}&` || ""}${groupBy && `groupBy=${groupBy}` || ""}${billed && `billed=${billed}` || ""}`)
  },

  buybackAddShipped(locationId: number, sellOrderIds: number[], filename: string) {
    return buybackInstance.post<any>(`/buyback/shipments`, {
      sellOrderIds,
      locationId,
      filename
    },
      {
        responseType: 'arraybuffer',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/xlsx, application/pdf',
        }
      });
  },

  locations() {
    return buybackInstance.get<LocationResponseType>(`/locations`);
  },

  repairs(model?: string, modelId?: number | string, storage?: number | string) {
    return buybackInstance.get<RepairsResponseType>(`/repairs?${model && `model=${model}&` || ""}${modelId && `modelId=${modelId}&` || ""}${storage && `storage=${storage}` || ""}`);
  },

  models() {
    return buybackInstance.get<any>(`/v2/models`);
  },

  download(filename: string, type: string) {
    return buybackInstance.get<any>(`/download/${filename}?type=${type}`, {
      responseType: 'arraybuffer',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/xlsx, application/pdf',
      }
    });
  },

  generateReport(locationId: string, type: string) {
    return buybackInstance.get<any>(`/generate-admin-report?locationId=${locationId.includes("ALL") ? 'ALL' : locationId}&type=${type}&groupBy=false`, {
      responseType: 'arraybuffer',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/xlsx, application/pdf',
      }
    });
  },

  upload(formData: any) {
    return buybackInstance.post<UploadResponseType>(`/upload`,
      formData,
      {
        headers: { 'Content-Type': 'multipart/form-data' }
      }
    )
  },

  createUsers(formData: any) {
    return buybackInstance.post<any>(`/create-user`,
      formData,
      {
        headers: { 'Content-Type': 'multipart/form-data' }
      }
    )
  },


  downloadStock(startDate: string, endDate: string) {
    return buybackInstance.get<any>(`/stock-report/${startDate}/${endDate}`, {
      responseType: 'arraybuffer',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/xlsx, application/pdf',
      }
    });
  },

  submitForDocument(requestForDocument: RequestForDocument) {
    return buybackInstance.post<any>(`/buyback/submit-order-for-document`, {
      requestForDocument
    });
  },

  generateVoucher(requestForVoucher: RequestForVoucher) {
    return buybackInstance.post<any>(`/voucher/generate-voucher`, {
      requestForVoucher
    });
  }
}

type BuybackResponseType = {
  data: {
    id: number,
    model: string,
    brand: string,
    storage: string,
    offer: number,
    offerWithRepairs?: number,
    buybackUUID: any,
    defects?: string[],
    message?: string
  },
  errorCode?: string,
  message?: string,
  success?: boolean
}

type DeleteResponseType = {
  data: {
    success: string,
    type?: string,
    message: string
  }
}

type SubmitResponseType = {
  data: {
    sellOrderId?: number,
    model?: string,
    storage?: string,
    imei?: string,
    repairs?: string
  }
}

type RejectResponseType = {
  data: {
    succes: boolean
  }
}

type InventoryItem = {
  id?: number,
  name?: string,
  storage?: string,
  order_date?: any,
  offer?: number,
  defects?: string,
  imei_code?: string
}

type InventoryResponseType = {
  data: {
    brandsCount?: any,
    inventory: InventoryItem[],
    totalItems?: number
  }
}

type ShipmentItem = {
  transport_document_id?: number,
  filename?: string,
  shipment_date?: any,
  tracking_number?: string,
  shipment_status?: string,
  count?: string,
  sell_order_id?: number,
  brand?: string,
  model?: string,
  storage?: string,
  color?: string,
}

type ShippedResponseType = {
  shipments: ShipmentItem[],
  totalItems?: number
}

type LocationItemType = {
  admin_id: string,
  location_id: number,
  name: string
}

type LocationResponseType = {
  locations: LocationItemType[]
}

type RepairsResponseType = {
  repairs: string[]
}

type SessionResponseType = {
  data: {
    id: string,
    email: string,
    jwt_token_secret: string,
    name: string,
    activated_at: string,
    deactivated_at: string | null,
    created_at: string,
    role: string,
    locations: string[],
    supplier: string
  }
}

type ModelType = {
  name: string;
  storage: string;
}

type UploadResponseType = {
  data: {
    status: number,
    data: {
      success: string | boolean,
      message: string
    }
  }
}
