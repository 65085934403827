import React, { useEffect } from "react";
import "antd/dist/antd.css";
import "../styles/App.scss";
import { useSelector, useDispatch } from "react-redux";
import { Route, Routes, useNavigate } from "react-router-dom";
import '../i18n/config';

import Login from "pages/auth/login";
import Dashboard from "pages/dashboard";
import BuyBackProcess from "./buyback-process";
import InManagementPhones from "./in-management-phones";
import SendPhones from "./send-phones";
import SentPhones from "./sent-phones";
import TransportSheets from "./transport-sheets";
import Invoice from "./invoice";
import AcceptedModels from "./accepted-models";
import RaportStoc from "./raport-stoc";
import Docs from "./docs";
import Voucher from "components/flow/Voucher";

import { AppStateType } from "../redux/Store";
import { loginActions, userLogout } from "../redux/reducers/AuthReducer";
import CreateUsers from "./create-users";

function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isAuth, role } = useSelector((state: AppStateType) => state.login);

  useEffect(() => {
    const loggedToken = localStorage.getItem("loggedToken2");
    if (loggedToken) {
      dispatch(loginActions.setIsAuth(true));
      if (role === "OPERATOR_BUYBACK") {
        navigate("/buyback");
      } else {
        navigate("/in-management-phones");
      }
    } else {
      dispatch(userLogout())
    }
  }, [role]);
  return (
    <Routes>
      <Route path="/auth" element={<Login />} />
      <Route path="/buyback" element={<BuyBackProcess />} />
      <Route path="/in-management-phones" element={<InManagementPhones />} />
      <Route path="/send-phones" element={<SendPhones />} />
      <Route path="/sent-phones" element={<SentPhones />} />
      <Route path="/transport-sheets" element={<TransportSheets />} />
      <Route path="/invoice" element={<Invoice />} />
      <Route path="/accepted-models" element={<AcceptedModels />} />
      <Route path="/voucher" element={<Voucher />} />
      <Route path="/docs" element={<Docs />} />
      <Route path="/raport-stoc" element={<RaportStoc />} />
      <Route path="/create-users" element={<CreateUsers />} />
      <Route path="*" element={<Dashboard />} />
    </Routes>
  );
}

export default App;
