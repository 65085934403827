import React, { forwardRef } from "react";
import Input from "./Input";
import { searchIcon } from "icons/Icons";
import { Select } from "antd";
import { useTranslation } from "react-i18next";

const { Option } = Select;

export type SearchVariant = "yellow" | "blue" | "green";

type SearchProps = {
  id?: string;
  children?: React.ReactNode | React.ReactNode[];
  brandsCount?: any[];
  selectDefaultValue?: string;
  selectOptions?: string[];
  onChange?: any;
  handleSelect?: any;
};

function SearchWithRef(props: SearchProps, ref: any) {
  const { t } = useTranslation();

  const {
    brandsCount,
    children,
    selectDefaultValue,
    selectOptions,
    onChange,
    handleSelect,
  } = props;

  return (
    <div className="search-container mb-5">
      <div className="d-flex align-items-center justify-content-between">
        <div className="search-wrapper bg-main-bd d-flex align-items-center">
          {selectDefaultValue && (
            <Select defaultValue={selectDefaultValue} onChange={handleSelect}>
              {selectOptions &&
                selectOptions.map((selectOption, index) => (
                  <Option key={index} value={selectOption}>
                    {selectOption}
                  </Option>
                ))}
            </Select>
          )}
          <div className="search-divider"></div>
          <Input
            type="text"
            variant="search"
            className="relative"
            startIcon={searchIcon}
            placeholder="Search"
            onChange={onChange}
            classNameContainer="w-100 d-flex"
          />
        </div>
        <div className="d-flex align-items-center justify-content-end">
          {/* <Button
            className="pl-5"
            variant="outlined"
            size="small"
            startIcon={filterIcon}
            onClick={() => {}}
          >
            Filtreaza
          </Button> */}
          {children}
        </div>
      </div>
      {brandsCount && Object.entries(brandsCount).length && (
        <div className="d-flex align-items-center brands-count-container mt-4">
          <p className="font-weight-medium font-size-15 text-body mr-3">
            {t("phones-total")}
          </p>
          {Object.entries(brandsCount).map((brand, index) => (
            <div
              key={index}
              className="d-flex align-items-center brands-count font-size-14 text-dark mr-4"
            >
              <div className="d-flex flex-column align-items-center mr-1">
                <span className="line"></span>
                <span className="font-weight-semi-bold">{brand[1]}</span>
              </div>
              <p className="font-weight-regular">{brand[0]}</p>
            </div>
          ))}
        </div>
      ) || null}
    </div>
  );
}

const Search = forwardRef(SearchWithRef);

export default Search as typeof SearchWithRef;
