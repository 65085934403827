
import { loginRedirect } from "hoc/LoginRedirect";
import Menu from "components/Menu";
import Navbar from "components/Navbar";
import { useTranslation } from "react-i18next";
import Button from "components/organisms/Button";
import { useState } from "react";
import { buybackAPI } from "api/buybackAPI";
import { motion } from "framer-motion";
import { topToBottomSlideIn } from "utils/animations";
import Toast from "components/organisms/Toast";


const CreateUsers = () => {
  const { t } = useTranslation();
  const [waiting, setWaiting] = useState<boolean>(false);
  const [fileName, setFileName] = useState({} as any);
  const [toast, setToast] = useState({} as any);


  const onSubmit = async () => {
    setWaiting(true);
    let formData = new FormData();
    formData.append("file", fileName);

    const { data } = await buybackAPI.createUsers(formData);
    setWaiting(false);
    if (data.success) {
      setFileName({});
      setToast({
        id: 1,
        icon: "/images/check-circle.svg",
        title: t("users-ok"),
        subtitle: t("users-ok-subtitle"),
        link: "",
      })
    } else {
      setToast({
        id: 1,
        icon: "/images/x-circle",
        title: t("users-notok"),
        subtitle: t("users-notok-subtitle"),
        link: "",
      })
    }
  };

  const handleFileChange = (event: any) => {
    const fileUploaded = event.target.files[0];
    console.log(fileUploaded);
    setFileName(fileUploaded);
  };

  return (
    <>
      <Menu></Menu>
      <div className="page-content">
        <Navbar
          title={t("admin")}
          subtitle={t("create-users")}
          showCard={true}
          showGoBackButton={false}
          showCancelButton={false}
        ></Navbar>
        <div className="content d-flex" >
          <div className="flex-1 flow-container w-100 m-5">
            <div className="step-content-card w-60 mx-auto">
              <p className="font-size-20 font-weight-bold text-align-center mb-1">
                {t("upload-create-users")}
              </p>
              <p className="font-size-15 text-dark opacity-07 text-align-center mb-4"
                dangerouslySetInnerHTML={{ __html: t("template-create-users") }} />


              <div className="d-flex align-items-center justify-content-center mt-4">
                <input type="file" name="file" id="file" className="inputfile" onChange={handleFileChange}
                />
                <label htmlFor="file" className="text-body-darker">
                  <svg className="mr-2" fill="#737E92" xmlns="http://www.w3.org/2000/svg" width="20" height="17" viewBox="0 0 20 17"><path d="M10 0l-5.2 4.9h3.3v5.1h3.8v-5.1h3.3l-5.2-4.9zm9.3 11.5l-3.2-2.1h-2l3.4 2.6h-3.5c-.1 0-.2.1-.2.1l-.8 2.3h-6l-.8-2.2c-.1-.1-.1-.2-.2-.2h-3.6l3.4-2.6h-2l-3.2 2.1c-.4.3-.7 1-.6 1.5l.6 3.1c.1.5.7.9 1.2.9h16.3c.6 0 1.1-.4 1.3-.9l.6-3.1c.1-.5-.2-1.2-.7-1.5z"></path></svg>
                  {fileName?.name || t('choose-file')}
                </label>
                <Button
                  variant="primary"
                  className="ml-4"
                  wait={waiting}
                  disabled={!fileName?.name}
                  type="submit"
                  onClick={onSubmit}
                >
                  {t("upload")}
                </Button>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div className="toast-container">
        <div className="toast-wrapper">
          {Object.keys(toast).length && (
            <motion.div
              key={toast.id}
              initial="hidden"
              animate="enter"
              exit="exit"
              className="item"
              variants={topToBottomSlideIn}
            >
              <Toast
                id={toast.id}
                icon={toast.icon}
                title={toast.title}
                subtitle={toast.subtitle}
                link={""}
                onClickProp={() => { setToast({}) }} />
            </motion.div>
          ) || null}
        </div>
      </div>

    </>
  );
};

export default loginRedirect(CreateUsers);
