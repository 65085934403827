const stepperFlowSteps = [
  {
    id: 0,
    isActive: true,
    isCompleted: false,
    isDisabled: false,
    title: "general.phone-data",
    titleHeader: "general.phone-corrrect-data",
    subtitleHeader: "general.flippo",
    actionConfirm: "general.correct-data",
    actionDecline: "general.incorrect-data",
  },
  {
    id: 1,
    isActive: false,
    isCompleted: false,
    isDisabled: false,
    title: "general.imei-title",
    titleHeader: "general.imei-error",
    subtitleHeader: "general.flippo",
    actionConfirm: "general.continue",
  },
  {
    id: 2,
    isActive: false,
    isCompleted: false,
    isDisabled: false,
    title: "phone-defects",
    titleHeader: "other-defects",
    subtitleHeader: "general.flippo",
    actionConfirm: "general.continue",
  },
  {
    id: 3,
    isActive: false,
    isCompleted: false,
    isDisabled: false,
    title: "offer-buyback",
    titleHeader: "offer-subtitle",
    subtitleHeader: "general.flippo",
    actionConfirm: "accept",
    actionDecline: "decline",
  },
];

stepperFlowSteps.forEach((el) => Object.freeze(el));

const initialState = {
  sellerName: "",
  buybackID: "",
  sellOrderID: 0,
  phoneDetails: [],
  IMEI: "",
  repairs: [],
  uncheckedRepairs: [
    // {
    //   id: 2,
    //   title: "Stare baterie",
    // },
    // {
    //   id: 3,
    //   title: "Port incarcare",
    // },
    // {
    //   id: 6,
    //   title: "Camera fata",
    // },
    // {
    //   id: 7,
    //   title: "Camera spate",
    // },
  ],
  defects: [],
  buybackOffer: 0,
  gdpr: "",
  protocol: "",
  currentStep: {
    id: 0,
    name: "StartFlow",
    navbarTitle: "welcome.title",
    navbarSubtitle:
      "welcome.subtitle",
    navbarShowDiscardBtn: false,
  },
  steps: [
    {
      id: 0,
      name: "StartFlow",
      navbarTitle: "welcome.title",
      navbarSubtitle:
        "welcome.subtitle",
      navbarShowDiscardBtn: false,
    },
    {
      id: 1,
      name: "ClientDetails",
      navbarTitle: "welcome.title",
      navbarSubtitle: "welcome.buyback",
      navbarShowDiscardBtn: true,
    },
    {
      id: 2,
      name: "StepperFlow",
      navbarTitle: "welcome.title",
      navbarSubtitle: "welcome.buyback",
      navbarShowDiscardBtn: true,
    },
    {
      id: 3,
      name: "Voucher",
      navbarTitle: "general.voucher",
      navbarSubtitle:  "general.voucher",
      navbarShowDiscardBtn: true,
    },
    {
      id: 4,
      name: "EndFlow",
      navbarTitle: "welcome.title",
      navbarSubtitle: "welcome.buyback",
      navbarShowDiscardBtn: true,
    },
  ],
  currentStepperFlowStep: 0,
  flowSteps: [
    {
      id: 0,
      isActive: true,
      isCompleted: false,
      isDisabled: false,
      title: "general.phone-data",
      titleHeader: "general.phone-corrrect-data",
      subtitleHeader: "general.flippo",
      actionConfirm: "general.correct-data",
      actionDecline: "general.incorrect-data",
    },
    {
      id: 1,
      isActive: false,
      isCompleted: false,
      isDisabled: false,
      title: "general.imei-title",
      titleHeader: "general.imei-error",
      subtitleHeader: "general.flippo",
      actionConfirm: "general.continue",
    },
    {
      id: 2,
      isActive: false,
      isCompleted: false,
      isDisabled: false,
      title: "phone-defects",
      titleHeader:
        "other-defects",
      subtitleHeader: "general.flippo",
      actionConfirm: "general.continue",
    },
    {
      id: 3,
      isActive: false,
      isCompleted: false,
      isDisabled: false,
      title: "offer-buyback",
      titleHeader: "offer-subtitle",
      subtitleHeader: "general.flippo",
      actionConfirm: "accept",
      actionDecline: "decline",
    },
  ],
  reason: "",
  additionalInformation: "",
  isBuybackProcessDone: false,
  marketingApproval: false,
  customerEmail: '',

};

export const phoneDetailsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case "SET_SELLER_NAME":
      return { ...state, sellerName: action.payload };
    case "SET_BUYBACK_ID":
      return { ...state, buybackID: action.payload };
    case "SET_SELL_ORDER_ID":
      return { ...state, sellOrderID: action.payload };
    case "SET_PHONE_DETAILS":
      return { ...state, phoneDetails: action.payload };
    case "SET_IMEI":
      return { ...state, IMEI: action.payload };
    case "SET_REPAIRS":
      return { ...state, repairs: action.payload };
    case "SET_UNCHECKED_REPAIRS":
      return { ...state, uncheckedRepairs: action.payload };
    case "SET_DEFECTS":
      return { ...state, defects: action.payload };
    case "SET_BUYBACK_OFFER":
      return { ...state, buybackOffer: action.payload };
    case "SET_GDPR":
      return { ...state, gdpr: action.payload };
    case "SET_PROTOCOL":
      return { ...state, protocol: action.payload };
    case "SET_CURRENT_STEP":
      return { ...state, currentStep: action.payload };
    case "SET_STEPS":
      return { ...state, steps: action.payload };
    case "SET_CURRENT_STEPPER_FLOW_STEP":
      return { ...state, currentStepperFlowStep: action.payload };
    case "SET_STEPPER_FLOW_STEPS":
      return { ...state, flowSteps: action.payload };
    case "SET_CANCEL_REASON":
      return { ...state, reason: action.payload };
    case "SET_CANCEL_ADDITIONAL_INFORMATION":
      return { ...state, additionalInformation: action.payload };
    case "SET_IS_BUYBACK_PROCESS_DONE":
      return { ...state, isBuybackProcessDone: action.payload };
    case "SET_MARKETING_APPROVAL":
      return { ...state, marketingApproval: action.payload };
    case "RESET_STATE":
      return {
        ...initialState,
        flowSteps: stepperFlowSteps,
      };
    case "SET_CUSTOMER_EMAIL":
        return { ...state, customerEmail: action.payload };
    default:
      return state;
  }
};
