import { buybackAPI } from "api/buybackAPI";

export const buildBlob = (data: any, filename: string) => {
  const blob = new Blob([data]);
  const link = document.createElement("a");
  link.href = window.URL.createObjectURL(blob);
  link.download = filename;
  link.click();
}

export const downloadFile = async (filename?: string, type?: string, data?: any) => {
    try {
      if(filename && type) {
        const response = await buybackAPI.download(filename, type);
        buildBlob(response.data, filename);
      } else {
        buildBlob(data, "Transport sheet.pdf");
      }
    } catch (error) {
    }
  };


export const donwloadExcel = async (location: string, type: string) => {
  try {
    const response = await buybackAPI.generateReport(location, type);
    buildBlob(response.data, "Export.xlsx");
  } catch (error) {
  }
};
