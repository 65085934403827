import { arrowDown, arrowUp } from "icons/Icons";
import React, { forwardRef, useState } from "react";
import Input from "./Input";
import { useTranslation } from "react-i18next";

type DropdownProps = {
  id?: string;
  options: any[];
  isListOpen?: boolean;
  onClick?: any;
  setSelectedItemProp?: any;
  selectedItem: any;
  customOption?: React.ReactNode
};

function DropdownWithRef(props: DropdownProps, ref: any) {
  const { t } = useTranslation();
  const { id, options,selectedItem, setSelectedItemProp } = props;

  const [showItems, setShowItems] = useState(false);

  const dropDown = () => {
    setShowItems(!showItems);
  };

  const selectItem = (item: any) => (event: any) => {
    setSelectedItemProp(item);
    setShowItems(false);
  };

  return (
    <div className="select-box--box font-size-14 mr-3 order-1">
      <div className="select-box--container">
        <div
          className="select-box--selected-item font-weight-regular text-body-table"
          onClick={dropDown}
        >
          {t("general.view")}:{" "}
          <strong className="ml-1 text-secondary">{selectedItem.value}</strong>
        </div>
        <div className="select-box--arrow">
          {showItems ? (
            <span
              className="d-flex align-items-center mr-2"
              dangerouslySetInnerHTML={{ __html: arrowUp }}
            ></span>
          ) : (
            <span
              className="d-flex align-items-center mr-2"
              dangerouslySetInnerHTML={{ __html: arrowDown }}
            ></span>
          )}
        </div>

        <div
          style={{ display: showItems ? "block" : "none" }}
          className={"select-box--items"}
        >
          {options.map((option, index) => (
            <div
              className="select-box--item text-body-darker"
              key={index}
              onClick={selectItem(option)}
            >
              <Input
                id={option.id}
                name="awb"
                type="radio"
                variant="radio"
                classNameContainer="d-flex align-items-center"
                className="d-flex order-1 mr-2"
                startLabel={option.value}
                checked={option.value === selectedItem.value ? true : false}
              />
            </div>
          ))}
          {
            props.customOption && <div>
              {props.customOption}
            </div> || null
          }
        </div>
      </div>
    </div>
  );
}

const DropdownWCustom = forwardRef(DropdownWithRef);

export default DropdownWCustom as typeof DropdownWithRef;
