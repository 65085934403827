import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import HeaderFlow from "./HeaderFlow";
import FlowContentRendered from "./FlowContentRenderer";
import Button from "components/organisms/Button";
import Input from "components/organisms/Input";

import { useDispatch, useSelector } from "react-redux";
import {
  setCurrentStepAction,
  setCurrentStepperFlowStepAction,
  setGDPRAction,
  setMarketingApprovalAction,
  setProtocolAction,
} from "redux/actions/PhoneDetailsAction";

import { exclamationCircle } from "icons/Icons";

import { motion } from "framer-motion";

import { topToBottomSlideIn } from "utils/animations";
import { fixBody, unfixBody } from "utils/functions";

import PopupPhoneSheet from "components/PopupPhoneSheet";
import PopupSuccess from "components/PopupSuccess";
import { buybackAPI } from "../../api/buybackAPI";
import { RequestForVoucher } from "../../types/types";
import { useTranslation } from "react-i18next";

const EndFlow = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { sellOrderID, buybackOffer, customerEmail } = useSelector(
    (state: any) => state.phone
  );

  const { email, supplier } = useSelector((state: any) => state.login);
  const emailOperator = useSelector((state: any) => state.login.email);
  useEffect(() => {
    setConditions([
      {
        id: 0,
        title: t("finalize-flow.step-1"),
        isChecked: false,
        display: true,
        textTooltip: "Info",
      },
      {
        id: 1,
        title: t("finalize-flow.step-2"),
        isChecked: false,
        display: true,
        textTooltip: "Info",
      },
      {
        id: 2,
        title: t("finalize-flow.step-3"),
        isChecked: false,
        display: true,
        textTooltip: "Info",
      },
      {
        id: 3,
        title: t("finalize-flow.step-4"),
        isChecked: false,
        display: true,
        textTooltip: "Info",
      },
      {
        id: 4,
        title:
          t("finalize-flow.step-5", { buybackOffer: buybackOffer, sellOrderID: (sellOrderID !== 0 || sellOrderID !== false) && sellOrderID }),
        isChecked: false,
        display: !(supplier?.toLowerCase() === "emag") && true,
        textTooltip: "Info",
      },
      {
        id: 5,
        title: "S-a semnat contractul de cedarea dreptului de proprietate",
        isChecked: false,
        display: supplier?.toLowerCase() === "emag" && true,
        textTooltip: "Info",
      },
    ]);
  }, [sellOrderID]);

  const [files, setFiles] = useState<any[]>([]);
  const [isDisabled, setIsDisabled] = useState(true);
  const [conditions, setConditions] = useState<any[]>([]);

  const [isPopupSuccessOpen, setIsPopupSuccessOpen] = useState(false);
  const [isPopupPhoneSheetOpen, setIsPopupPhoneSheetOpen] = useState(false);

  const [todayDate, setTodayDate] = useState<string>("");

  const [waiting, setWaiting] = useState<boolean>(false);

  const [errorMessage, setErrorMessage] = useState<string>("");

  useEffect(() => {
    buildTodayDate();
  }, []);

  useEffect(() => {
    handleCheckboxesStatus();
  }, [conditions]);

  const buildTodayDate = () => {
    let today = new Date();
    let dd = String(today.getDate()).padStart(2, "0");
    let mm = String(today.getMonth() + 1).padStart(2, "0");
    let yyyy = today.getFullYear();
    let todayDate2String = dd + "/" + mm + "/" + yyyy;
    setTodayDate(todayDate2String);
  };

  const openSheetPopup = () => {
    setIsPopupPhoneSheetOpen(true);
    fixBody();
  };

  const openSuccessPopup = () => {
    setIsPopupSuccessOpen(true);
    fixBody();
  };

  const closeSheetPopup = () => {
    setIsPopupPhoneSheetOpen(false);
    unfixBody();
  };

  const closeSuccessPopup = () => {
    setIsPopupSuccessOpen(false);
    unfixBody();
  };

  const setFiless = (filess: any) => {
    setFiles(filess);
  };

  const onCheck = (condition: any, inputValue: boolean) => {
    if (condition.id === 5) {
      dispatch(setMarketingApprovalAction(inputValue));
    }
    condition.isChecked = inputValue;
    let conditionsCopy = [...conditions];
    const indexConditions = conditionsCopy.indexOf(condition);
    conditionsCopy[indexConditions] = condition;
    setConditions([...conditionsCopy]);
  };

  const setSelectedFiles = () => {
    dispatch(setGDPRAction(files[0]));
    dispatch(setProtocolAction(files[1]));
  };

  const handleCheckboxesStatus = () => {
    for (let i = 0; i < conditions.length; i++) {
      if (
        conditions[i].display === true &&
        conditions[i].isChecked === false &&
        conditions[i].id !== 5
      ) {
        setIsDisabled(true);
        return;
      }
      setIsDisabled(false);
    }
  };
  const generateVoucher = async () => {
    setWaiting(true);
    setIsDisabled(true);
    const requestForVoucher: RequestForVoucher = {
      sellOrderID: sellOrderID,
      email: customerEmail,
      emailOperator: emailOperator,
      buybackOffer: buybackOffer,
      marketingApproval: true
    }
    try {
      const response = await buybackAPI.generateVoucher(requestForVoucher);
      if (response.data.isVoucherGenerated === true) {
        openSheetPopup();
      } else {
        throw new Error(t("emag.no-voucher"));
      }
      setWaiting(false);
      setIsDisabled(false);
    } catch (error) {
      setWaiting(false);
      setIsDisabled(false);
      setErrorMessage(t("emag.no-voucher"));
    }
  }

  const handleClick = () => {
    supplier?.toLowerCase() === "emag"
      ? generateVoucher()
      : openSheetPopup();
  };

  return (
    <>
      <motion.div
        initial="hidden"
        animate="enter"
        exit="exit"
        variants={topToBottomSlideIn}
        className="content d-flex"
      >
        <div className="flex-1 flow-container w-100 flow-start ">
          <FlowContentRendered>
            <div className="d-flex flex-column align-items-center justify-content-center">
              <HeaderFlow
                title="finalize-flow.check-steps"
                subtitle="general.flippo"
                iconSize="lg"
              />
            </div>
            <div className="step-content-container w-80 m-auto flex-1">
              <div className="step-content-card">
                <div className="d-flex flex-column">
                  {conditions.map(
                    (checkbox: any, index: number) =>
                      checkbox.display && (
                        <Input
                          id={checkbox.id}
                          className="relative d-flex mr-4 order-1"
                          key={checkbox.id}
                          type="checkbox"
                          size="medium"
                          variant="checkbox"
                          startLabel={checkbox.title}
                          checked={checkbox.isChecked}
                          selectedFiles={[]}
                          classNameStartLabel="d-flex align-items-center text-body line-height-100"
                          classNameEndLabel="d-flex"
                          classNameLabelContainer="d-flex"
                          classNameContainer="d-flex align-items-center input-conditions"
                          onChange={(e: any) =>
                            onCheck(checkbox, e.target.checked)
                          }
                        />
                      )
                  )}
                </div>
              </div>
            </div>

            <div className="d-flex justify-content-center">
              <Button
                variant={isDisabled ? "disabled" : "primary"}
                size="large"
                className="mt-4"
                disabled={isDisabled}
                onClick={() => {
                  setSelectedFiles();
                  handleClick();
                  // openSheetPopup();
                  // dispatch(setIsBuybackProcessDoneAction(true));
                  // submitBuyback();
                }}
              >
                {t("general.confirm")}
              </Button>
            </div>
          </FlowContentRendered>
        </div>
      </motion.div>
      {isPopupPhoneSheetOpen && (
        <PopupPhoneSheet
          todayDate={todayDate}
          closeSheetPopup={closeSheetPopup}
          openSuccessPopup={openSuccessPopup}
        />
      )}
      {isPopupSuccessOpen && <PopupSuccess />}
    </>
  );
};

export default EndFlow;
