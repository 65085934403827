import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Menu from "components/Menu";
import Navbar from "components/Navbar";
import Voucher from "components/flow/Voucher";
import EndFlow from "components/flow/EndFlow";
import StartFlow from "components/flow/StartFlow";
import ClientDetails from "components/flow/ClientDetails";
import StepperFlow from "components/flow/StepperFlow";

import { IProcessStep } from "interfaces/stepper";

import { loginRedirect } from "hoc/LoginRedirect";

import {
  resetStateAction,
  setCurrentStepAction,
  setStepperFlowStepsAction,
} from "redux/actions/PhoneDetailsAction";
import { useTranslation } from "react-i18next";

const BuyBackProcess = () => {
  const dispatch = useDispatch();
  const { steps, currentStep, flowSteps } = useSelector((state: any) => state.phone);

  const [processStepsState, setprocessStepsState] =
    useState<IProcessStep[]>(steps);
  const [title, setTitle] = useState("");
  const [subtitle, setSubtitle] = useState("");
  const [showCard, setShowCard] = useState(false);
  const [showGoBackButton, setShowGoBackButton] = useState(true);
  const [showPopup, setShowPopup] = useState(false);
  const { email, supplier } = useSelector((state: any) => state.login);

  const displayPopup = () => {
    setShowPopup(true);
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  useEffect(() => {
    handleNavbarContent();
  }, [currentStep]);

  const handleNavbarContent = () => {
    if (currentStep.id === 0) {
      setShowGoBackButton(false);
    } else {
      setShowGoBackButton(true);
    }

    if (currentStep.id === 0 || currentStep.id === 1) {
      setShowCard(true);
      setTitle("welcome.title");
      setSubtitle("welcome.subtitle");
    } else {
      setShowCard(false);
      setTitle("Home page");
      setSubtitle("welcome.buyback");
    }
  };

  const goNext = () => {
    if (currentStep.id === 1) {
      if (supplier.toLowerCase() === 'vodafone') {
        let flowStepsCopy: any[] = [];
        for (let i = 0; i < flowSteps.length; i++) {
          flowStepsCopy[i] = JSON.parse(JSON.stringify(flowSteps[i]));
        }

        flowStepsCopy[1].isDisabled = true;
        flowStepsCopy[2].isDisabled = true;
        dispatch(setStepperFlowStepsAction(flowStepsCopy));

      }
    }

    if (currentStep.id === 2) {
      if (supplier?.toLowerCase() === "emag" || supplier?.toLowerCase() === "mobiup" || supplier?.toLowerCase() === "technomarket" || supplier.toLowerCase() === 'vodafone') {
        dispatch(setCurrentStepAction(steps[currentStep.id + 1]));
      } else {
        dispatch(setCurrentStepAction(steps[currentStep.id + 2]));
      }
    } else {
      dispatch(setCurrentStepAction(steps[currentStep.id + 1]));
    }
    setprocessStepsState(processStepsState);
  };

  const goPrev = () => {
    if (currentStep.id === 4) {
      if (supplier?.toLowerCase() === "emag" || supplier?.toLowerCase() === "mobiup" || supplier?.toLowerCase() === "technomarket" || supplier.toLowerCase() === 'vodafone') {
        dispatch(setCurrentStepAction(steps[currentStep.id - 1]));
      } else {
        dispatch(setCurrentStepAction(steps[currentStep.id - 2]));
      }
    } else {
      dispatch(setCurrentStepAction(steps[currentStep.id - 2]));
    }
    setprocessStepsState(processStepsState);

    currentStep.id === 1 && dispatch(resetStateAction());
  };

  const { t } = useTranslation();

  return (
    <>
      <Menu></Menu>
      <div className="page-content">
        <div>
          <Navbar
            title={t(title)}
            subtitle={t(subtitle)}
            showCard={showCard}
            showGoBackButton={currentStep.id !== 3 && currentStep.id !== 4 && showGoBackButton}
            showCancelButton={currentStep.id === 2 && true}
            closePopupProp={closePopup}
            showPopupProp={showPopup}
            onClickProp={goPrev}
          ></Navbar>
          {currentStep.id === 0 && (
            <StartFlow nextProcessStep={() => goNext()}></StartFlow>
          )}
          {currentStep.id === 1 && (
            <ClientDetails nextProcessStep={() => goNext()}></ClientDetails>
          )}
          {currentStep.id === 2 && (
            <StepperFlow
              nextProcessStep={() => goNext()}
              badClientData={() => goPrev()}
            ></StepperFlow>
          )}

          {currentStep.id === 3 && <Voucher></Voucher>}

          {currentStep.id === 4 && <EndFlow></EndFlow>}
        </div>
      </div>
    </>
  );
};

export default loginRedirect(BuyBackProcess);
