import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import HeaderFlow from "../flow/HeaderFlow";
import Button from "components/organisms/Button";
import PopupSuccess from "components/PopupSuccess";
import PopupPhoneSheet from "components/PopupPhoneSheet";
import Input, { InputVariant } from "components/organisms/Input";

import { errorIcon, checkedIcon } from "../../icons/Icons";

import { motion } from "framer-motion";

import { topToBottomSlideIn } from "../../utils/animations";

import { fixBody, unfixBody } from "../../utils/functions";

import { buybackAPI } from "../../api/buybackAPI";
import { RequestForDocument } from "../../types/types";
import { downloadFile } from "../../utils/api";
import { setCurrentStepAction, setCustomerEmailAction } from "../../redux/actions/PhoneDetailsAction";
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";
import Tooltip from "antd/lib/tooltip";

import { exclamationCircle } from "icons/Icons";


type PhoneIMEIProps = {};

const phoneRORegex =
  /^(?:(?:(?:00\s?|\+)40\s?|0)(?:7\d{2}\s?\d{3}\s?\d{3}|(21|31)\d{1}\s?\d{3}\s?\d{3}|((2|3)[3-7]\d{1})\s?\d{3}\s?\d{3}|(8|9)0\d{1}\s?\d{3}\s?\d{3}))$/;

const phoneBGRegex = /^\+359[1-9][0-9]{7,8}$/;
const Voucher = (props: PhoneIMEIProps) => {
  const dispatch = useDispatch();
  const { steps, sellOrderID, buybackOffer, marketingApproval, customerEmail } = useSelector(
    (state: any) => state.phone
  );

  const emailOperator = useSelector((state: any) => state.login.email);

  const [email, setEmail] = useState<string>("");
  const [giftCard, setGiftCard] = useState<string>("");
  const [customerName, setCustomerName] = useState<string>("");
  const [customerPhoneNumber, setCustomerPhoneNumber] = useState<string>("");
  const [customerAddress, setCustomerAddress] = useState<string>("");
  const [emailVariant, setEmailVariant] = useState<InputVariant>("outlined");
  const [customerNameVariant, setCustomerNameVariant] = useState<InputVariant>("outlined");
  const [customerPhoneNumberVariant, setCustomerPhoneNumberVariant] = useState<InputVariant>("outlined");
  const [customerAddressVariant, setCustomerAddressVariant] = useState<InputVariant>("outlined");
  const [giftCardVariant, setGiftCardVariant] = useState<InputVariant>("outlined");

  const [isDisabled, setIsDisabled] = useState<boolean>(true);

  const [isPopupSuccessOpen, setIsPopupSuccessOpen] = useState(false);
  const [isPopupPhoneSheetOpen, setIsPopupPhoneSheetOpen] = useState(false);

  const [todayDate, setTodayDate] = useState<string>("");

  const [errorMessage, setErrorMessage] = useState<string>("");

  const [waiting, setWaiting] = useState<boolean>(false);

  const { t } = useTranslation();

  const { supplier } = useSelector((state: any) => state.login);

  const checkValid = () => {
    if (supplier.toLowerCase() === "technomarket") {
      return [customerNameVariant, customerPhoneNumberVariant, customerAddressVariant, giftCardVariant, emailVariant].every((val) => val === "valid");
    } else if (supplier.toLowerCase() === 'vodafone') {
      return [customerNameVariant, customerAddressVariant, giftCardVariant, emailVariant].every((val) => val === "valid");
    } else {
      return [customerNameVariant, customerAddressVariant, emailVariant].every((val) => val === "valid");
    }
  }

  useEffect(() => {
    if (customerEmail) {
      dispatch(setCurrentStepAction(steps[4]))
    }

    buildTodayDate();
  }, []);

  const buildTodayDate = () => {
    let today = new Date();
    let dd = String(today.getDate()).padStart(2, "0");
    let mm = String(today.getMonth() + 1).padStart(2, "0");
    let yyyy = today.getFullYear();
    let todayDate2String = dd + "/" + mm + "/" + yyyy;
    setTodayDate(todayDate2String);
  };

  const openSuccessPopup = () => {
    setIsPopupSuccessOpen(true);
    fixBody();
  };

  const openSheetPopup = () => {
    setIsPopupPhoneSheetOpen(true);
    fixBody();
  };

  const closeSheetPopup = () => {
    setIsPopupPhoneSheetOpen(false);
    unfixBody();
  };

  function validateEmail(email: string) {
    const mailFormat = /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.+-]+\.[a-zA-Z]{2,4}$/;

    if (email == "" || !email.match(mailFormat)) {
      return false;
    }
    return true;
  }

  function emailOnBlurHandler(e: React.FormEvent<HTMLInputElement>) {
    const email = e.currentTarget.value;
    if (!validateEmail(e.currentTarget.value)) {
      setIsDisabled(true);
      setEmailVariant("error");
    } else {
      setIsDisabled(false);
      setEmailVariant("valid");
    }
  }

  function emailOnChangeHandler(e: React.FormEvent<HTMLInputElement>) {
    setEmail(e.currentTarget.value);
    setErrorMessage("");
    if (!validateEmail(e.currentTarget.value)) {
      setIsDisabled(true);
      setEmailVariant("error");
    } else {
      setIsDisabled(false);
      setEmailVariant("valid");
    }
  }
  function customerPhoneNumberOnChangeHandler(e: React.FormEvent<HTMLInputElement>) {
    setCustomerPhoneNumber(e.currentTarget.value);
    const regex = supplier.toLowerCase() === "technomarket" ? phoneBGRegex : phoneRORegex;
    if (!regex.test(e.currentTarget.value)) {
      setIsDisabled(true);
      setCustomerPhoneNumberVariant("error");
    } else {
      setCustomerPhoneNumberVariant("valid");
    }
  }
  function customerNameOnChangeHandler(e: React.FormEvent<HTMLInputElement>) {
    setCustomerName(e.currentTarget.value);
    if (e.currentTarget.value.length < 3) {
      setIsDisabled(true);
      setCustomerNameVariant("error");
    } else {
      setCustomerNameVariant("valid");
    }
  }
  function customerAddressOnChangeHandler(e: React.FormEvent<HTMLInputElement>) {
    setCustomerAddress(e.currentTarget.value);
    if (e.currentTarget.value.length < 3) {
      setIsDisabled(true);
      setCustomerAddressVariant("error");
    } else {
      setCustomerAddressVariant("valid");
    }
  }

  function giftCardHandler(e: React.FormEvent<HTMLInputElement>) {
    setGiftCard(e.currentTarget.value);
    const voucherLength = supplier.toLowerCase() === "technomarket" ? [13] : supplier.toLowerCase() === 'vodafone' ? [7, 8] : 0;
    const voucherNotAccepted = supplier.toLowerCase() === 'vodafone' ? ["0000000", "00000000", "1234567", "12345678"] : [];

    const vodafoneStartByLenght = {
      "7": "7",
      "8": "3"
    };

    if (!e.currentTarget.value.length || (voucherLength && voucherLength.indexOf(e.currentTarget.value.length) === -1) ||
      (voucherNotAccepted.length && voucherNotAccepted.indexOf(e.currentTarget.value) !== -1)) {
      setIsDisabled(true);
      setGiftCardVariant("error");
    } else {
      if (supplier.toLowerCase() === 'vodafone' && (e.currentTarget.value.length === 8 || e.currentTarget.value.length === 7)) {
        if (vodafoneStartByLenght[e.currentTarget.value.length] !== e.currentTarget.value[0]) {
          setIsDisabled(true);
          setGiftCardVariant("error");
          return;
        }
      }
      setGiftCardVariant("valid");
    }


  }
  function timeout(delay: number) {
    return new Promise(res => setTimeout(res, delay));
  }

  const submitEmail = async () => {

    setWaiting(true);
    setIsDisabled(true);

    const requestForDocument: RequestForDocument = {
      sellOrderID: sellOrderID,
      customerName: customerName,
      customerPhoneNumber: customerPhoneNumber,
      customerAddress: customerAddress,
      email: email,
      emailOperator: emailOperator,
      buybackOffer: buybackOffer,
      marketingApproval: marketingApproval,
      giftCard: giftCard
    }
    try {
      const response = await buybackAPI.submitForDocument(requestForDocument);
      if (response.data.data.isPdfGenerated === true) {
        await timeout(10000);
        await downloadFile(`contract_${sellOrderID}.pdf`, "contract");
        dispatch(setCurrentStepAction(steps[4]))
        dispatch(setCustomerEmailAction(email))
      } else {
        throw new Error(t("emag.no-voucher"));
      }
      setWaiting(false);
      setIsDisabled(false);
    } catch (error) {
      setWaiting(false);
      setIsDisabled(false);
      setErrorMessage(t("emag.no-voucher"));
    }

  }

  return (
    <>
      <motion.div
        initial="hidden"
        animate="enter"
        exit="exit"
        variants={topToBottomSlideIn}
        className="d-flex flex-column content"
      >
        <div className="d-flex flex-column flex-1 flow-container justify-content-around">
          <div>
            <div className="d-flex flex-column align-items-center justify-content-center">
              <HeaderFlow
                title={t("voucher-data")}
                subtitle=""
                iconSize="lg"
              />
            </div>
            <div className="step-content-card w-60 mx-auto">
              <Input
                id="nume"
                className="relative mt-2"
                variant={customerNameVariant}
                type="text"
                startLabel={t("general.full-name")}
                bottomLabel={errorMessage}
                classNameBottomLabel="font-size-14 font-weight-regular text-red text-align-start opacity-07 mb-2"
                endIcon={
                  customerNameVariant === "error"
                    ? errorIcon
                    : customerNameVariant === "valid"
                      ? checkedIcon
                      : ""
                }
                size={"medium"}
                placeholder={t("general.full-name")}
                value={customerName}
                onChange={customerNameOnChangeHandler}
              />
              {supplier?.toLowerCase() === "mobiup" || supplier?.toLowerCase() === "technomarket" ? (
                <Input
                  id="telefon"
                  className="relative mt-2"
                  variant={customerPhoneNumberVariant}
                  type="text"
                  startLabel={t("general.phone")}
                  bottomLabel={errorMessage}
                  classNameBottomLabel="font-size-14 font-weight-regular text-red text-align-start opacity-07 mb-2"
                  endIcon={
                    customerPhoneNumberVariant === "error"
                      ? errorIcon
                      : customerPhoneNumberVariant === "valid"
                        ? checkedIcon
                        : ""
                  }
                  size={"medium"}
                  placeholder={t("general.phone")}
                  value={customerPhoneNumber}
                  onChange={customerPhoneNumberOnChangeHandler}
                />
              ) : null}
              <Input
                id="address"
                className="relative mt-2"
                variant={customerAddressVariant}
                type="text"
                startLabel={t("general.address")}
                bottomLabel={errorMessage}
                classNameBottomLabel="font-size-14 font-weight-regular text-red text-align-start opacity-07 mb-2"
                endIcon={
                  customerAddressVariant === "error"
                    ? errorIcon
                    : customerAddressVariant === "valid"
                      ? checkedIcon
                      : ""
                }
                size={"medium"}
                placeholder={t("general.address")}
                value={customerAddress}
                onChange={customerAddressOnChangeHandler}
              />
              {
                (emailOperator.includes("technomarket") || supplier.toLowerCase() === 'vodafone') && (
                  <div className="relative">
                    <Input
                      id="giftCard"
                      className="relative mt-2"
                      variant={giftCardVariant}
                      labelTooltip={
                        <Tooltip title={t("voucher.validation")}>
                          <span
                            className=""
                            style={{ position: "relative", top: "3px", left: "3px" }}
                            dangerouslySetInnerHTML={{ __html: exclamationCircle }}
                          ></span>
                        </Tooltip>
                      }
                      type="number"
                      startLabel={t("general.gift-card")}
                      bottomLabel={errorMessage}
                      classNameBottomLabel="font-size-14 font-weight-regular text-red text-align-start opacity-07 mb-2"
                      endIcon={
                        giftCardVariant === "error"
                          ? errorIcon
                          : giftCardVariant === "valid"
                            ? checkedIcon
                            : ""
                      }
                      size={"medium"}
                      placeholder={t("general.gift-card")}
                      value={giftCard}
                      onChange={giftCardHandler}
                    />
                  </div>
                )
              }
              <Input
                id="email"
                className="relative mt-2"
                variant={emailVariant}
                type="text"
                startLabel={t("general.email-label")}
                bottomLabel={errorMessage}
                classNameBottomLabel="font-size-14 font-weight-regular text-red text-align-start opacity-07 mb-2"
                endIcon={
                  emailVariant === "error"
                    ? errorIcon
                    : emailVariant === "valid"
                      ? checkedIcon
                      : ""
                }
                size={"medium"}
                placeholder={t("general.email-label")}
                value={email}
                onBlur={emailOnBlurHandler}
                onChange={emailOnChangeHandler}
              />

            </div>
          </div>
          <div className="d-flex justify-content-center">
            <Button
              size="large"
              variant={!checkValid() ? "disabled" : "primary"}
              className="mt-4"
              disabled={!checkValid()}
              wait={waiting}
              onClick={() => {
                // openSheetPopup();
                submitEmail();
              }}
            >
              {t("general.confirm")}
            </Button>
          </div>
        </div>
      </motion.div>

      {isPopupPhoneSheetOpen && (
        <PopupPhoneSheet
          todayDate={todayDate}
          closeSheetPopup={closeSheetPopup}
          openSuccessPopup={openSuccessPopup}
        />
      )}
      {isPopupSuccessOpen && <PopupSuccess />}
    </>
  );
};

export default Voucher;
